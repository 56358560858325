.layout-wrap {
    width: 100%;
    height: 100%;
    display: flex;
}
.left-menu {
    width: 208px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    background-color: white;
    z-index: 99;
}
.right-cont {
    width: auto;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    overflow-x: auto;
    overflow-y: auto;
}
.topNav {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    overflow-y: hidden;
    transition: left 0.3s ease;
    line-height: 56px;
    z-index: 999;
}
.right-main {
    flex: 1;
}
