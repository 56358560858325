.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.appMain {
    margin-top: 60px;
    margin-left: 218px;
    background: #fafafa;
    transition: margin-left 0.35s ease;
}

.appPage {
    padding: 10px 20px;
}
.pageTable {
    width: 100%;
}
.pageContent {
    width: 99%;
    height: 100%;
    min-height: 500px;
    padding: 10px;
    background: #ffffff;
}

/* 调整所有滚动条的样式 */
::-webkit-scrollbar {
    width: 6px; /* 滚动条宽度 */
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* 滚动条轨道背景颜色 */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* 滚动条滑块颜色 */
    border-radius: 10px; /* 滚动条滑块圆角 */
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5); /* 鼠标悬停时滚动条滑块颜色 */
}

.pageTables {
    width: 100%;
    overflow: auto;
}
