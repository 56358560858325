/* .rightContent {
    position: relative;
    right: 10px;
    top: 10px;
} */

.headerAll {
    justify-content: space-between;
    flex-shrink: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: sticky;
    top: 0;
    z-index: 101;
    background-color: #fff;
    display: flex;
    height: 56px;
}
.menuButton {
    float: left;
}

.searchInput {
    float: left;
    position: relative;
    left: 0px;
    top: 12px;
}
