.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background: #f0f2f5;
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
}
.loginTitle {
    position: absolute;
    text-align: center;
    width: 100%;
    margin: auto;
    top: 100px;
}
.content {
    margin: auto;
}
.ant-form-item-explain-error {
    text-align: left;
    font-size: 12px;
}

#components-form-demo-normal-login .login_form {
    max-width: 300px;
}
#components-form-demo-normal-login .login_form_forgot {
    float: right;
}
#components-form-demo-normal-login .ant_col_rtl .login_form_forgot {
    float: left;
}
#components-form-demo-normal-login .login_form_button {
    width: 100%;
}
