.paginationStyle {
    text-align: right;
    margin: 20px 0;
    width: 100%;
    height: auto;
    display: inline-block;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-pagination {
    display: inline-block;
}
